import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useMobile } from 'common-nextjs';
import React from 'react';
import Expander from '~/components/Expander/Expander';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsFacetGroup } from '~/typings/services/rails/facets';

export interface FilteringSectionProps {
  defaultExpanded?: boolean;
  facet?: RailsFacetGroup;
  selectionText?: React.ReactNode;
  title: string;
  rightContent?: React.ReactNode;
}

const FilteringSection: React.FC<
  React.PropsWithChildren<FilteringSectionProps>
> = props => {
  const { children, facet, rightContent, selectionText, title } = props;
  const mobile = useMobile();
  const { isSelected } = useFacetFiltering();

  const selectionTextDefault = facet?.children
    ?.filter(f => isSelected(facet, f))
    ?.map(f => f.name)
    ?.join(', ');

  const showExpandedVersion = mobile || facet?.slug !== 'category';

  if (showExpandedVersion) {
    return (
      <Expander
        trigger={(handleOpen, open, handleClose) => (
          <button
            className="flex w-full flex-1 items-center py-3 text-base font-semibold focus:outline-none"
            onClick={open ? handleClose : handleOpen}
          >
            <div>{title}</div>
            {!!rightContent && <div className="ml-2">{rightContent}</div>}

            <div className="flex-1" />

            {!!(selectionText || selectionTextDefault) && (
              <div className="ml-4 truncate text-sm text-green-500">
                {selectionText || selectionTextDefault}
              </div>
            )}

            {open ? (
              <MinusIcon className="ml-4 h-4 w-4 flex-shrink-0 " />
            ) : (
              <PlusIcon className="ml-4 h-4 w-4 flex-shrink-0 " />
            )}
          </button>
        )}
        defaultExpanded={props.defaultExpanded || false}
      >
        {children}
        <div className="pb-4" />
      </Expander>
    );
  } else {
    return (
      <section className="mb-4 text-sm" aria-label={title}>
        <div className="sticky top-0 flex items-center space-x-2 bg-white pb-1.5 font-semibold">
          <header>{title}</header>

          {rightContent}
        </div>

        {children}
      </section>
    );
  }
};

export default FilteringSection;
