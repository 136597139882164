import {
  FilterState,
  FlattenedFilterState,
} from '~/typings/services/rails/facets';
import { displayPrice } from 'utils';

export default function filterStatePriceFormat(
  filterState: FlattenedFilterState | FilterState,
) {
  if (filterState.min_price && filterState.max_price) {
    return `${displayPrice(filterState.min_price, false)} - ${displayPrice(
      filterState.max_price,
      false,
    )}`;
  } else if (filterState.min_price && !filterState.max_price) {
    return `> ${displayPrice(filterState.min_price, false)}`;
  } else if (!filterState.min_price && filterState.max_price) {
    return `< ${displayPrice(filterState.max_price, false)}`;
  }
}
