import React, { useEffect, useState } from 'react';
import { Button, NumberInput } from 'ui';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {
  onSubmit?: () => void;
}

const PriceFilterForm: React.FC<Props> = () => {
  const { filterState, setPrices } = useFacetFiltering();

  const [min, setMin] = useState(filterState.min_price);
  const [max, setMax] = useState(filterState.max_price);

  useEffect(() => {
    setMin(filterState.min_price);
    setMax(filterState.max_price);
  }, [filterState.max_price, filterState.min_price]);

  function handleSubmit() {
    setPrices(min, max);
  }

  return (
    <form
      className="flex items-center"
      onSubmit={e => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <NumberInput
        inputClassName="py-1"
        leftAdornment
        rightAdornment
        name="min_price"
        min={3}
        max={10000}
        placeholder="Min"
        thousandSeparator
        prefix="$"
        value={min || ''}
        onValueChange={v => setMin(v.floatValue)}
        style={{
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderRight: 0,
        }}
        onBlur={handleSubmit}
      />
      <NumberInput
        inputClassName="py-1"
        leftAdornment
        rightAdornment
        name="max_price"
        min={3}
        max={10000}
        placeholder="Max"
        thousandSeparator
        prefix="$"
        value={max || ''}
        onValueChange={v => setMax(v.floatValue)}
        style={{ borderRadius: 0, borderRight: 0 }}
        onBlur={handleSubmit}
      />
      <Button type="submit" className="h-10 rounded-l-none !px-4">
        &gt;
      </Button>
    </form>
  );
};

export default PriceFilterForm;
