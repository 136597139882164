import { useMobile } from 'common-nextjs';
import React from 'react';
import ShopLinkTitleHack from '~/components/Links/ShopLinkTitleHack';
import Facet from '~/components/Search/Facet';
import SimpleFilterCheckbox from '~/components/Search/FilteringList/SimpleFilterCheckbox';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { fireAppliedFilter } from '~/services/analytics/events/search';
import { BaseFacet, BaseFacetGroup } from '~/typings/services/rails/facets';
import doesLanderQueryContainADetail from '~/utils/doesLanderQueryContainADetail';

interface Props {
  facet: BaseFacetGroup;
  detail: BaseFacet;
  singleValue?: boolean;
}

const ControlledFilterCheckboxItem: React.FC<Props> = ({
  facet,
  detail,
  singleValue,
}) => {
  const {
    changeFacetSelection,
    isSelected,
    transferableFilterState,
    type,
    lander,
  } = useFacetFiltering();
  const selected = isSelected(facet, detail);
  const mobile = useMobile();

  const children = (
    <>
      {detail.name} <Facet detail={detail} />
    </>
  );

  const landerQueryHasADetail = doesLanderQueryContainADetail(lander);

  if (type === 'browse' && detail.url && !landerQueryHasADetail) {
    if (mobile) {
      return (
        <ShopLinkTitleHack
          internalRouter
          path={detail.url}
          query={transferableFilterState}
          className="flex w-full items-center justify-between py-2 text-sm"
          anchorText={detail.destination_lander_name}
          displayedText={
            <SimpleFilterCheckbox
              checked={selected}
              onChange={() => {
                fireAppliedFilter(facet, detail);
                changeFacetSelection(facet, detail, singleValue, false);
              }}
            >
              {children}
            </SimpleFilterCheckbox>
          }
        />
      );
    }

    return (
      <ShopLinkTitleHack
        internalRouter
        path={detail.url}
        query={transferableFilterState}
        className="flex items-center"
        anchorText={detail.destination_lander_name}
        displayedText={
          <SimpleFilterCheckbox
            checked={selected}
            onChange={() => {
              fireAppliedFilter(facet, detail);
              changeFacetSelection(facet, detail, singleValue, false);
            }}
          >
            {children}
          </SimpleFilterCheckbox>
        }
      />
    );
  }

  return (
    <SimpleFilterCheckbox
      checked={selected}
      onChange={() => changeFacetSelection(facet, detail, singleValue, true)}
    >
      {children}
    </SimpleFilterCheckbox>
  );
};

export default ControlledFilterCheckboxItem;
