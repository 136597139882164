import React from 'react';
import { clsx } from 'clsx';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: never;
}

export const Checkbox: React.FC<CheckboxProps> = ({ className, ...props }) => (
  <input
    // place this here to avoid warnings when it's a complicated controlled component
    onChange={() => {}}
    {...props}
    type="checkbox"
    className={clsx(
      'form-checkbox border-grey-600 text-turf-green-500 disabled:bg-grey-500 rounded-sm leading-loose transition-colors duration-200 focus:ring-0 disabled:cursor-not-allowed',
      className,
      !props.checked && !props.disabled && 'hover:border-slate-green-500',
    )}
  />
);

export interface CheckboxWithLabelProps
  extends Omit<CheckboxProps, 'children'> {
  checkboxClassName?: string;
  children: React.ReactNode;
}

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  checkboxClassName,
  children,
  className,
  ...props
}) => {
  return (
    <label className={clsx('flex space-x-2', className)}>
      <Checkbox {...props} className={clsx(checkboxClassName, 'mt-1')} />
      <span>{children}</span>
    </label>
  );
};
