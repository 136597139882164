import React from 'react';
import ModalTitle from '~/components/Modal/ModalTitle';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {
  onClickApply: () => void;
}

const FilterModalTitle: React.FC<Props> = ({ onClickApply }) => {
  const { clearAll } = useFacetFiltering();

  return (
    <ModalTitle className="sticky top-0 z-10 flex rounded-t bg-white px-4 py-3">
      <div className="flex-1 text-left">
        <button
          className="text-primary-500 pr-4 text-sm font-semibold"
          onClick={clearAll}
        >
          Clear
        </button>
      </div>
      <div className="flex-1">Filter</div>
      <div className="flex-1 text-right">
        <button
          className="text-primary-500 pl-4 text-sm font-semibold md:hidden"
          onClick={onClickApply}
        >
          Apply
        </button>
      </div>
    </ModalTitle>
  );
};

export default FilterModalTitle;
