import { useMutation } from '@tanstack/react-query';
import { KnownCookie } from 'common-types';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useCookie } from 'react-use';
import { Input } from 'ui';
import { updateFastShipping } from '~/api/user';
import ErrorMessage from '~/components/Form/ErrorMessage';
import Tooltip from '~/components/Form/Tooltip';
import Image from '~/components/Image';
import Facet from '~/components/Search/Facet';
import DetailList from '~/components/Search/FilteringList/DetailList';
import FilteringSection from '~/components/Search/FilteringList/FilteringSection';
import SimpleFilterCheckbox from '~/components/Search/FilteringList/SimpleFilterCheckbox';
import { useUserSearchFiltersContext } from '~/contexts/UserSearchFilters';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import CA_FLAG from '~/public/static/ca-flag.png';
import HelpIcon from '~/public/static/svg/HelpIcon';
import {
  fireAppliedCanadianSeller,
  fireAppliedFastShipping,
} from '~/services/analytics/events/search';
import {
  CanadianSellerSearchFilter,
  FastShippingSearchFilter,
} from '~/typings/services/rails/userSearchFilters';

const fastShippingRe = /free_shipping$/g;

interface Props {}

const DeliveryFilter: React.FC<Props> = () => {
  const { facets, isSelected, changeFacetSelection } = useFacetFiltering();
  const { filters } = useUserSearchFiltersContext();

  const itemTypeFacet = facets?.find(f => f.slug === 'item_type');
  const fastShippingChild = itemTypeFacet?.children?.find(c =>
    fastShippingRe.test(c.slug),
  );

  const fastShipping = filters.find(f => f.slug === 'fast_shipping') as
    | FastShippingSearchFilter
    | undefined;

  const canadianSeller = filters.find(f => f.slug === 'canadian_seller') as
    | CanadianSellerSearchFilter
    | undefined;

  if (
    !fastShipping?.display &&
    !canadianSeller?.display &&
    !fastShippingChild
  ) {
    return null;
  }

  return (
    <FilteringSection
      defaultExpanded
      title="Delivery"
      facet={{ slug: 'delivery', name: 'Delivery' }}
      rightContent={
        (fastShipping?.display && (
          <Tooltip
            tooltip="Filter to items from sellers who can Fast Delivery near you. Items typically arrive in 1 to 4 business days. Shipping times are not guaranteed."
            direction="top"
          >
            <HelpIcon />
          </Tooltip>
        )) ||
        (canadianSeller?.display && (
          <Tooltip
            tooltip="Items from sellers near you arrive more quickly and cost less to ship."
            direction="top"
          >
            <HelpIcon />
          </Tooltip>
        ))
      }
    >
      <DetailList className="max-h-80">
        {fastShipping?.display && <FastDeliveryFilter />}
        {canadianSeller?.display && <CanadianSellerFilter />}

        {itemTypeFacet && fastShippingChild && (
          <SimpleFilterCheckbox
            checked={isSelected(itemTypeFacet, fastShippingChild)}
            onChange={() =>
              changeFacetSelection(itemTypeFacet, fastShippingChild)
            }
          >
            {fastShippingChild.name} <Facet detail={fastShippingChild} />
          </SimpleFilterCheckbox>
        )}
      </DetailList>
    </FilteringSection>
  );
};

const FastDeliveryFilter = () => {
  const { filters } = useUserSearchFiltersContext();
  const router = useRouter();
  const [fastShippingCookie, setFastShippingCookie] = useCookie(
    'fast_shipping_filter_enabled' as KnownCookie,
  );

  const fastShipping = filters.find(f => f.slug === 'fast_shipping') as
    | FastShippingSearchFilter
    | undefined;

  const [editing, setEditing] = useState(false);
  const [zip, setZip] = useState(fastShipping?.zip || '');

  const { error, mutate: update } = useMutation(updateFastShipping, {
    onSuccess: () => {
      setFastShippingCookie('1');
      router.reload();
    },
  });

  function toggle() {
    fireAppliedFastShipping(zip);
    if (fastShippingCookie === '1') {
      setFastShippingCookie('');
    } else {
      setFastShippingCookie('1');
    }
    router.reload();
  }

  return (
    <div className="flex flex-wrap">
      <SimpleFilterCheckbox
        checked={fastShippingCookie === '1'}
        onChange={toggle}
      >
        Fast Delivery to
      </SimpleFilterCheckbox>

      {editing ? (
        <form
          className="mt-1 inline-flex items-center space-x-4"
          onSubmit={e => {
            e.preventDefault();
            update({ zip });
          }}
          onClick={e => e.stopPropagation()}
        >
          <Input
            autoFocus
            onChange={e => setZip(e.target.value)}
            value={zip}
            style={{ width: 88 }}
            onFocus={e => e.target.select()}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                setEditing(false);
              }
            }}
          />

          <button
            className="text-blue-500"
            type="submit"
            style={{ textDecoration: 'underline' }}
          >
            Save
          </button>
        </form>
      ) : (
        <button
          className="ml-1 text-blue-500"
          style={{ textDecoration: 'underline' }}
          onClick={e => {
            e.stopPropagation();
            setEditing(true);
          }}
        >
          {zip || 'Enter ZIP code'}
        </button>
      )}

      <ErrorMessage errors={error} />
    </div>
  );
};

const CanadianSellerFilter = () => {
  const router = useRouter();
  const [canadianSellersCookie, setCanadianSellersCookie] = useCookie(
    'canadian_sellers_filter_enabled' as KnownCookie,
  );

  function toggle() {
    if (canadianSellersCookie === '1') {
      fireAppliedCanadianSeller(false);
      setCanadianSellersCookie('');
    } else {
      fireAppliedCanadianSeller(true);
      setCanadianSellersCookie('1');
    }
    router.reload();
  }

  return (
    <SimpleFilterCheckbox
      checked={canadianSellersCookie === '1'}
      onChange={toggle}
    >
      <span className="flex items-center space-x-1">
        <span>Only Canadian sellers</span>
        <Image src={CA_FLAG} className="h-auto" width={24} />
      </span>
    </SimpleFilterCheckbox>
  );
};

export default DeliveryFilter;
