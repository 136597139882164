import React from 'react';
import FilteringSection from '~/components/Search/FilteringList/FilteringSection';
import PriceFilterForm from '~/components/Search/PriceFilterForm';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import filterStatePriceFormat from '~/utils/filterStatePriceFormat';

interface Props {}

const PriceSection: React.FC<Props> = () => {
  const { filterState } = useFacetFiltering();
  return (
    <FilteringSection
      title="Price"
      facet={{ slug: 'price', name: 'Price' }}
      selectionText={filterStatePriceFormat(filterState)}
      defaultExpanded
    >
      <PriceFilterForm />
    </FilteringSection>
  );
};

export default PriceSection;
