import { RailsLander } from '~/typings/services/rails/landers';

export default function doesLanderQueryContainADetail(lander?: RailsLander) {
  const landerQueryKeys = lander?.query ? Object.keys(lander.query) : [];

  let landerQueryContainsMoreThanJustCategoryOrModel: boolean;

  if (landerQueryKeys.includes('model')) {
    // category, brand, model are always included.
    landerQueryContainsMoreThanJustCategoryOrModel = landerQueryKeys.length > 3;
  } else {
    landerQueryContainsMoreThanJustCategoryOrModel = landerQueryKeys.length > 1;
  }

  return landerQueryContainsMoreThanJustCategoryOrModel;
}
