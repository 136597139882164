'use client';

import { Input, InputProps } from './Input';
import React from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

export type NumberInputProps = Omit<
  InputProps,
  'defaultValue' | 'value' | 'type'
> &
  NumberFormatProps & {
    changeProperty?: keyof NumberFormatValues;
  };

export const NumberInput: React.FC<NumberInputProps> = ({ name, ...props }) => (
  <NumberFormat
    name={name}
    customInput={Input}
    inputMode="numeric"
    {...props}
  />
);
