import React from 'react';
import DetailList from '~/components/Search/FilteringList/DetailList';
import FilteringSection from '~/components/Search/FilteringList/FilteringSection';
import SimpleFilterCheckbox from '~/components/Search/FilteringList/SimpleFilterCheckbox';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {}

const SortFilterSection: React.FC<Props> = () => {
  const { filterState, changeFacetSelection, sorts } = useFacetFiltering();

  if (!sorts?.options) {
    return null;
  }

  return (
    <FilteringSection
      title="Sort"
      facet={{ slug: 'sort', name: 'Sort' }}
      selectionText={
        sorts?.options?.find(sort => sort.slug === filterState.sort)?.name ||
        'Default'
      }
    >
      <DetailList className="max-h-80">
        {sorts?.options.map(sort => (
          <SimpleFilterCheckbox
            key={sort.slug}
            checked={sort.slug === filterState.sort}
            onChange={() => changeFacetSelection('sort', sort.slug, true, true)}
          >
            {sort.name}
          </SimpleFilterCheckbox>
        ))}
      </DetailList>
    </FilteringSection>
  );
};

export default SortFilterSection;
