import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { DetailGuidanceContext, fetchDetailGuidance } from '~/api/categories';
import { RailsDetailGuidance } from '~/typings/services/rails/item';

export default function useDetailGuidance(
  categoryId?: number,
  context: DetailGuidanceContext = 'shop',
): UseQueryResult<RailsDetailGuidance[]> {
  return useQuery(
    ['detail-guidance', categoryId, context],
    () => fetchDetailGuidance(categoryId!, context),
    { enabled: !!categoryId },
  );
}
