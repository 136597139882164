import React from 'react';
import { getDetailPathById } from 'utils';
import CategoryListItem from '~/components/Search/FilteringList/CategoryListItem';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsCategory } from '~/typings/services/rails/item';

interface SimpleCategoryListProps {
  categories: RailsCategory[];
  selectedCategoryId?: number;
}

const SimpleCategoryList: React.FC<SimpleCategoryListProps> = ({
  categories,
  selectedCategoryId,
}) => {
  return (
    <>
      {categories.map(category => {
        const selected = selectedCategoryId === category.id;
        return (
          <li key={category.id}>
            <CategoryListItem selected={selected} category={category} />
          </li>
        );
      })}
    </>
  );
};

interface Props {}

const FilteringCategoryList: React.FC<Props> = () => {
  const { category: selectedCategory, categories } = useFacetFiltering();

  if (!categories) {
    return null;
  }

  const path = selectedCategory
    ? getDetailPathById(categories, selectedCategory.id)
    : categories;

  return (
    <section className="mb-4 text-sm">
      <ul className="space-y-0.5 md:space-y-1">
        {path?.map((category, i) => (
          <li
            key={category.id}
            style={{
              paddingLeft: selectedCategory ? 12 * i : 0,
            }}
          >
            <CategoryListItem
              selected={selectedCategory?.id === category.id}
              category={category}
            />

            {selectedCategory && i == path.length - 1 && category.children && (
              <ul className="space-y-0.5 pl-3 pt-1 md:space-y-1">
                <SimpleCategoryList categories={category.children} />
              </ul>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FilteringCategoryList;
