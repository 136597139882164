'use client';

import createSvg from '~/public/static/svg/createSvg';

export default createSvg(({ fill = '#02C874', height, width, ...props }) => (
  <svg
    width={width || '18'}
    height={height || '19'}
    viewBox="0 0 18 19"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M-297-100H78v667h-375z" />
      <path fill="none" d="M-297-10H78v40h-375z" />
      <path
        fill={fill}
        d="M0 14.415v2.048h6v-2.048H0zM0 2.122v2.049h10V2.122H0zm10 16.39v-2.049h8v-2.048h-8v-2.05H8v6.147h2zM4 6.22v2.048H0v2.05h4v2.048h2V6.22H4zm14 4.097V8.268H8v2.05h10zM12 6.22h2V4.17h4V2.123h-4V.073h-2V6.22z"
      />
    </g>
  </svg>
));
