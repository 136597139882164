import { useMobile } from 'common-nextjs';
import React from 'react';
import ShopLink from '~/components/Links/ShopLink';
import SimpleFilterCheckbox from '~/components/Search/FilteringList/SimpleFilterCheckbox';
import shouldForceSearchMode from '~/contexts/facetFiltering/shouldForceSearchMode';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { RailsCategory } from '~/typings/services/rails/item';

interface Props {
  category: RailsCategory;
  selected?: boolean;
}

const CategoryListItem: React.FC<Props> = ({ category, selected }) => {
  const mobile = useMobile();
  const {
    changeFacetSelection,
    transferableLanderQueryAndFilterState,
    type,
    filterState,
  } = useFacetFiltering();

  const spanCx = selected ? 'font-semibold' : '';

  // Mobile gets checkboxes right-aligned
  // Desktop gets simple links

  const renderedChildren = mobile ? (
    <SimpleFilterCheckbox checked={!!selected} onChange={() => null}>
      {category.name}
    </SimpleFilterCheckbox>
  ) : (
    <span className={spanCx}>{category.name}</span>
  );

  if (type === 'locker' || (mobile && type === 'search')) {
    return (
      <SimpleFilterCheckbox
        checked={!!selected}
        onChange={() => {
          changeFacetSelection('category', category.id, false, true);
        }}
      >
        <span className={spanCx}>{category.name}</span>
      </SimpleFilterCheckbox>
    );
  }

  if ((!mobile && type === 'search') || shouldForceSearchMode(filterState)) {
    return (
      <button
        onClick={() => {
          changeFacetSelection('category', [category.id], false, true);
        }}
      >
        {renderedChildren}
      </button>
    );
  }

  return (
    <ShopLink
      path={category.url}
      query={transferableLanderQueryAndFilterState}
      internalRouter={mobile}
      // Use the Link on desktop only
      onClick={
        mobile
          ? () => changeFacetSelection('category', [category.id], false, false)
          : undefined
      }
    >
      {renderedChildren}
    </ShopLink>
  );
};

export default CategoryListItem;
