import { clsx } from 'clsx';
import React from 'react';

const ModalTitle: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div className={clsx('mb-3 text-center text-xl', className)}>{children}</div>
);

export default ModalTitle;
