import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { TransitionClasses } from '@headlessui/react/dist/components/transitions/transition';
import { clsx } from 'clsx';
import { useMobile } from 'common-nextjs';
import React, { useEffect, useRef } from 'react';

const DIRECTION_MAP: Record<string, TransitionClasses> = {
  normal: {
    enter: 'ease-out duration-300',
    enterFrom: 'opacity-0 translate-y-8 -md:translate-y-4',
    enterTo: 'opacity-100 translate-0',
    leave: 'ease-in duration-150',
    leaveFrom: 'opacity-100 translate-y-0',
    leaveTo: 'opacity-0 translate-y-4  -md:translate-y-2',
  },
  right: {
    enter: 'ease-out duration-300',
    enterFrom: 'opacity-0 translate-y-8 md:translate-x-8 md:translate-y-0',
    enterTo: 'opacity-100 translate-0',
    leave: 'ease-in duration-150',
    leaveFrom: 'opacity-100 translate-y-0 md:translate-x-0 md:translate-y-0',
    leaveTo: 'opacity-0 translate-y-8 md:translate-x-8 md:translate-y-0',
  },
  left: {
    enter: 'ease-out duration-300',
    enterFrom: 'opacity-0 translate-y-8 md:-translate-x-8 md:translate-y-0',
    enterTo: 'opacity-100 translate-0',
    leave: 'ease-in duration-150',
    leaveFrom: 'opacity-100 translate-y-0 md:translate-x-0 md:translate-y-0',
    leaveTo: 'opacity-0 translate-y-8 md:-translate-x-8 md:translate-y-0',
  },
};

export interface NewModalProps {
  onOpen?: () => void;
  onRequestClose: () => void;
  open: boolean;
}

interface Props extends NewModalProps {
  direction?: keyof typeof DIRECTION_MAP;
  height?: 'normal' | 'tall' | 'short';
}

const UniqueFilteringModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  direction = 'normal',
  height = 'tall',
  open,
  onOpen,
  onRequestClose,
}) => {
  const modalBodyRef = useRef<HTMLDivElement>(null);
  const mobile = useMobile();

  useEffect(() => {
    if (open && onOpen) {
      onOpen();
    }
  }, [open, onOpen]);

  const closeButton = (
    <button
      onClick={onRequestClose}
      title="Close"
      className="absolute -top-20 right-4 rounded-full bg-white bg-opacity-50 p-4 text-white md:fixed md:right-4 md:top-4 md:bg-opacity-10"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  );

  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog
        as="div"
        static
        open={open}
        onClose={onRequestClose}
        className={clsx(
          'fixed inset-0 flex h-screen flex-col items-center justify-end md:items-start md:justify-start md:pt-0',
          {
            'pt-48': height === 'tall',
            'pt-64': height === 'normal',
            'pt-80': height === 'short',
          },
        )}
        style={{
          zIndex: 1000,
        }}
      >
        <TransitionChild
          as="div"
          className="bg-true-black fixed inset-0 bg-opacity-60 transition-opacity"
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        />

        {!mobile && closeButton}

        <TransitionChild
          as="div"
          {...DIRECTION_MAP[direction]}
          className="relative z-10 h-full w-full transform rounded-t bg-white text-left shadow-xl transition-all md:max-w-sm md:rounded-l-none md:rounded-r"
        >
          {mobile && closeButton}

          <DialogPanel
            ref={modalBodyRef}
            className="flex h-full max-h-[80vh] flex-col overflow-y-auto md:max-h-none"
          >
            {children}
          </DialogPanel>
        </TransitionChild>
      </Dialog>
    </Transition>
  );
};

export default UniqueFilteringModal;
