import { clsx } from 'clsx';
import React from 'react';
import { customScrollbarCx } from '~/components/CustomScrollbar';

export default function DetailList({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <ul
      className={clsx(
        'divide-grey-100 divide-y overflow-y-auto md:space-y-2 md:divide-y-0',
        customScrollbarCx,
        className,
      )}
    >
      {children}
    </ul>
  );
}
