import { clsx } from 'clsx';
import React from 'react';

interface Props extends React.BaseHTMLAttributes<HTMLDivElement> {}

export const customScrollbarCx =
  'md:scrollbar-thin md:scrollbar-thumb-grey-500 scrollbar-track-white hover:scrollbar-thumb-grey-600';

const CustomScrollbar = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }, ref) => (
    <div className={clsx(customScrollbarCx, className)} {...props} ref={ref}>
      {children}
    </div>
  ),
);

export default CustomScrollbar;
