'use client';

import React, { ReactNode } from 'react';
import { Divider } from 'ui';
import MarkdownContent from '~/components/MarkdownContent';
import HelpIcon from '~/public/static/svg/HelpIcon';
import { AnalyticsSource } from '~/services/analytics/AnalyticsInterfaces';
import { visitedDetailGuidance } from '~/services/analytics/events/item';
import { BaseFacetGroup } from '~/typings/services/rails/facets';
import {
  RailsCategory,
  RailsDetail,
  RailsDetailGuidance,
} from '~/typings/services/rails/item';
import ManagedModal from '../Modal/ManagedModal';

type Props = {
  category?: RailsCategory;
  context: 'shop' | 'list';
  detail?: BaseFacetGroup | RailsDetail;
  guidance: RailsDetailGuidance;
  customTrigger?: (handleOpen: () => void) => ReactNode;
  src?: AnalyticsSource;
};

const DetailGuidance: React.FC<Props> = ({
  category,
  context,
  customTrigger,
  detail,
  guidance,
  src,
}) => (
  <ManagedModal
    size="lg"
    title={`${category?.name || ''} ${detail?.name}`}
    trigger={handleOpen => {
      const open = () => {
        visitedDetailGuidance(context, src, detail?.slug, category?.slug);
        handleOpen();
      };

      if (customTrigger) {
        return customTrigger(open);
      }

      return (
        <button onClick={open} className="-m-2 ml-0 p-2">
          <HelpIcon />
        </button>
      );
    }}
  >
    {onRequestClose => (
      <>
        <Divider />
        <MarkdownContent source={guidance.guidance} />

        <div className="mt-4 flex justify-center">
          <button
            className="-m-2 -mx-4 block px-4 py-2 text-xs underline"
            onClick={onRequestClose}
          >
            Close
          </button>
        </div>
      </>
    )}
  </ManagedModal>
);

export default DetailGuidance;
