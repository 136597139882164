import { useMobile } from 'common-nextjs';
import React from 'react';
import DetailGuidance from '~/components/ItemDetails/DetailGuidance';
import ExpandableFilteringList from '~/components/Search/FilteringList/ExpandableFilteringList';
import FilteringCategoryList from '~/components/Search/FilteringList/FilteringCategoryList';
import FilteringSection from '~/components/Search/FilteringList/FilteringSection';
import DeliveryFilter from '~/components/Search/FilteringList/Sections/DeliveryFilter';
import FindADealSection from '~/components/Search/FilteringList/Sections/FindADealSection';
import PriceSection from '~/components/Search/FilteringList/Sections/PriceSection';
import SortFilterSection from '~/components/Search/FilteringList/Sections/SortFilterSection';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import useDetailGuidance from '~/hooks/useDetailGuidance';
import HelpIcon from '~/public/static/svg/HelpIcon';

export interface FilteringListProps {
  showCategoryFilters?: boolean;
  showDeliveryFilter?: boolean;
  showKeywordSearch?: boolean;
  showFindADeal?: boolean;
  showSort?: boolean;
}

const FilteringList: React.FC<FilteringListProps> = ({
  showCategoryFilters,
  showDeliveryFilter,
  showFindADeal,
  showKeywordSearch,
  showSort,
}) => {
  const mobile = useMobile();
  const { category, facets, filterState, categories, lander, type } =
    useFacetFiltering();
  const { data: detailGuidance } = useDetailGuidance(filterState.category?.[0]);

  const hardcodedPriceFacetIndex = 0;
  const hardcodedDeliveryFacetIndex = 1;
  const hardcodedFindADealIndex = facets.findIndex(
    facet =>
      facet.slug === 'seller_location' ||
      facet.slug === 'item_type' ||
      facet.slug === 'seller_type',
  );

  // Require categories to render, and require a category is already selected.
  // In some cases, like Sale landers, we want to still show category navigation.
  const categorySection = categories &&
    categories.length > 0 &&
    (lander || category || showCategoryFilters || type === 'search') && (
      <FilteringSection
        title={mobile ? 'Category' : 'Shop by Category'}
        selectionText={category?.full_name}
        facet={{ slug: 'category', name: 'Category' }}
      >
        <FilteringCategoryList />
      </FilteringSection>
    );

  return (
    <>
      <div className="divide-y">
        {showSort && <SortFilterSection />}
        {categorySection}

        {facets.map((facet, i) => {
          const additional: React.ReactNode = (
            <React.Fragment key={facet.slug}>
              {showDeliveryFilter && i === hardcodedDeliveryFacetIndex && (
                <DeliveryFilter />
              )}
              {i === hardcodedPriceFacetIndex && <PriceSection />}
              {showFindADeal && i === hardcodedFindADealIndex && (
                <FindADealSection />
              )}
            </React.Fragment>
          );

          if (
            facet.slug === 'category' ||
            facet.slug === 'sale' ||
            facet.slug === 'client_placement' || // hardcoded to ignore this facet PROD-1843
            !facet.children ||
            facet.children.length === 0
          ) {
            return additional;
          }

          const guidance = detailGuidance?.find(dg => dg.slug === facet.slug);

          return (
            <React.Fragment key={facet.slug}>
              {additional}

              <FilteringSection
                facet={facet}
                title={facet.name}
                rightContent={
                  guidance && (
                    <DetailGuidance
                      context="shop"
                      customTrigger={handleOpen => (
                        <button
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleOpen();
                          }}
                          className="relative font-semibold underline"
                          title={
                            guidance.guidance_link || `Help with ${facet.name}`
                          }
                        >
                          <span className="absolute -inset-4" />
                          <HelpIcon />
                        </button>
                      )}
                      guidance={guidance}
                      detail={facet}
                      src="filter"
                    />
                  )
                }
              >
                <ExpandableFilteringList facet={facet} />
              </FilteringSection>
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default FilteringList;
