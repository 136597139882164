import { useMobile } from 'common-nextjs';
import React from 'react';
import { Button } from 'ui';
import { pluralize, thousandsSeparator } from 'utils';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {
  onClick: () => void;
}

const FilterModalBottomButton: React.FC<Props> = ({ onClick }) => {
  const { paging } = useFacetFiltering();
  const mobile = useMobile();
  if (!mobile) {
    return null;
  }

  return (
    <div className="shadow-top sticky bottom-0 left-0 right-0 bg-white p-4">
      <Button fluid onClick={onClick}>
        View{' '}
        {paging
          ? `${thousandsSeparator(paging.total_count)}${
              paging.total_count_truncated ? '+' : ''
            }`
          : ''}{' '}
        {pluralize(paging?.total_count, 'Result', 'Results')}
      </Button>
    </div>
  );
};

export default FilterModalBottomButton;
