import React from 'react';
import ControlledFilterCheckboxItem from '~/components/Search/FilteringList/ControlledFilterCheckboxItem';
import DetailList from '~/components/Search/FilteringList/DetailList';
import FilteringSection from '~/components/Search/FilteringList/FilteringSection';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';

interface Props {}

const FindADealSection: React.FC<Props> = () => {
  const { facets, filterState } = useFacetFiltering();
  const filtered = facets.filter(facet => facet.children?.length! > 0);

  const firstSaleFacet = filtered.find(f => f.slug === 'sale');

  const itemTypeFacet = filtered.find(f => f.slug === 'item_type');
  const auctionFacet = itemTypeFacet?.children?.find(f => f.slug === 'auction');

  return (
    <FilteringSection
      facet={{ slug: 'find-a-deal', name: 'Find a Deal' }}
      selectionText={(filterState.sale || filterState.price_drop) && '(1)'}
      title="Find a Deal"
    >
      <DetailList className="max-h-80">
        {firstSaleFacet?.children?.[0] && (
          <div className="text-primary-500 font-semibold">
            <ControlledFilterCheckboxItem
              facet={firstSaleFacet}
              detail={firstSaleFacet.children[0]}
            />
          </div>
        )}

        <ControlledFilterCheckboxItem
          facet={{ name: 'Price Drops', slug: 'price_drop' }}
          detail={{
            slug: 'price_drop',
            name: 'Price Drops',
            id: 1,
            count: null,
          }}
          singleValue
        />

        {itemTypeFacet && auctionFacet && (
          <ControlledFilterCheckboxItem
            facet={itemTypeFacet}
            detail={auctionFacet}
          />
        )}
      </DetailList>
    </FilteringSection>
  );
};

export default FindADealSection;
