import React, { useState } from 'react';
import { Input } from 'ui';
import ControlledFilterCheckboxItem from '~/components/Search/FilteringList/ControlledFilterCheckboxItem';
import DetailList from '~/components/Search/FilteringList/DetailList';
import useFacetFiltering from '~/contexts/facetFiltering/useFacetFiltering';
import { BaseFacet, BaseFacetGroup } from '~/typings/services/rails/facets';

const numberRe = /(\d+)\w*\s*-\s*(\d+)\w*/i;
const lessThanRe = /(less than|<)\s*(\d+)/i;
const moreThanRe = /(more than|>)\s*(\d+)/i;

function shouldHideFacet(facetGroup: BaseFacetGroup, facet: BaseFacet) {
  if (facetGroup.slug === 'item_type' && facet.slug === 'auction') {
    return true;
  }

  return false;
}

function filterFacetChildren(
  facetGroup: BaseFacetGroup,
  search: string,
  isSelected: (value: BaseFacet) => boolean,
): BaseFacet[] {
  const number = Number(search.replace(/\D*/, ''));

  let re: RegExp;
  try {
    re = new RegExp(search.replace(/\W*/, ''), 'i');
  } catch (e) {}

  return (
    facetGroup.children?.filter(child => {
      if (shouldHideFacet(facetGroup, child)) {
        return false;
      }

      if (isSelected(child)) {
        return true;
      }

      if (!search) {
        return true;
      }

      const numberMatch = numberRe.exec(child.name);
      if (
        !Number.isNaN(number) &&
        number >= 0 &&
        numberMatch &&
        numberMatch.length > 1
      ) {
        return (
          number >= Number(numberMatch[1]) && number <= Number(numberMatch[2])
        );
      }

      const lessThanMatch = lessThanRe.exec(child.name);
      if (
        !Number.isNaN(number) &&
        number >= 0 &&
        lessThanMatch &&
        lessThanMatch.length > 1
      ) {
        return number <= Number(lessThanMatch[2]);
      }

      const moreThanMatch = moreThanRe.exec(child.name);
      if (
        !Number.isNaN(number) &&
        number >= 0 &&
        moreThanMatch &&
        moreThanMatch.length > 1
      ) {
        return number >= Number(moreThanMatch[2]);
      }

      return re ? re.test(child.name) : child.name.includes(search);
    }) || []
  );
}

interface Props {
  facet: BaseFacetGroup;
  maxHeight?: boolean;
}

const ExpandableFilteringList: React.FC<Props> = ({
  facet,
  maxHeight = true,
}) => {
  const { isSelected } = useFacetFiltering();
  const [search, setSearch] = useState('');

  if (!facet.children) {
    return null;
  }

  const children: BaseFacet[] = filterFacetChildren(facet, search, v =>
    isSelected(facet, v),
  );

  return (
    <>
      {/brand|model|flex|size|length|weight/.test(facet.slug) &&
        facet.children.length > 14 && (
          <Input
            className="mb-4"
            placeholder={`Search ${facet.name}`}
            value={search}
            onChange={e => {
              setSearch(e.target.value);
            }}
            type="search"
          />
        )}
      {search && children.length === 0 && (
        <div className="my-4 text-sm italic text-gray-500">No results</div>
      )}
      <DetailList className={maxHeight ? 'max-h-80' : undefined}>
        {children.map(child => (
          <li key={child.id} id={`facet-${facet.slug}-${child.slug}`}>
            <ControlledFilterCheckboxItem facet={facet} detail={child} />
          </li>
        ))}
      </DetailList>
    </>
  );
};

export default ExpandableFilteringList;
