import { useMobile } from 'common-nextjs';
import React from 'react';
import { Checkbox } from 'ui';

interface Props {
  checked: boolean;
  onChange: () => void;
}

const SimpleFilterCheckbox: React.FC<React.PropsWithChildren<Props>> = ({
  checked,
  children,
  onChange,
}) => {
  const mobile = useMobile();

  if (mobile) {
    return (
      <label className="flex w-full items-center justify-between py-2.5">
        <span>{children}</span>

        <Checkbox
          className="rounded-full"
          checked={checked}
          onChange={() => onChange()}
        />
      </label>
    );
  }

  return (
    <label className="flex items-center">
      <Checkbox
        className="mr-1.5"
        checked={checked}
        onChange={() => onChange()}
      />
      <span>{children}</span>
    </label>
  );
};

export default SimpleFilterCheckbox;
