'use client';

import createSvg from './createSvg';

export default createSvg<{ title?: string }>(
  ({ height, width, title, ...props }) => (
    <svg height={12} width={12} {...props} viewBox="0 0 10 10">
      <title>{title}</title>
      <path
        fill="currentColor"
        d="M5 0C2.24 0 0 2.24 0 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm.5 8.5h-1v-1h1v1zm1.035-3.875l-.45.46C5.725 5.45 5.5 5.75 5.5 6.5h-1v-.25c0-.55.225-1.05.585-1.415l.62-.63A.978.978 0 0 0 6 3.5c0-.55-.45-1-1-1s-1 .45-1 1H3a2 2 0 1 1 4 0c0 .44-.18.84-.465 1.125z"
      />
    </svg>
  ),
);
