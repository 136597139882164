import React from 'react';
import { thousandsSeparator } from 'utils';
import { BaseFacet } from '~/typings/services/rails/facets';

interface Props {
  detail: BaseFacet;
}

const Facet: React.FC<Props> = ({
  detail: { count, count_truncated: truncated },
}) => {
  // Covers when count is undefined or null
  // Still show the facet if it's 0.
  if (count == null) return null;

  return (
    <span className="text-slate-green-500">
      ({thousandsSeparator(count)}
      {truncated && '+'})
    </span>
  );
};

export default Facet;
